<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { USER_REQUEST } from "./store/actions/user";
export default {
  created: function() {
    if (this.$store.getters.isAuthenticated) {
      this.$store.dispatch(USER_REQUEST);
    }
  }
};
</script>

<style lang="scss">
@import "./assets/css/util.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// body,html {
//   margin:0;
// }
</style>
