<template>
    <header class="headerSuite"> 
        <div class="logo"> 
          <!-- <img @click="home" src="https://qa.controlt.com.co/APP/Content/main/res/LogoSidebar.png" /> -->
          <img @click="home" src="https://app.controlt.com.co/APP/Content/main/res/ControlTLogo.svg" />
          <span id="spanUser">{{name_company}}</span>
        </div>
        <!-- <div class="nav-username"> -->        
        <!-- </div> -->
        <!--  Menu en vista tipo No responsive -->  
        <div class="container-nav-options">  
        <span id="spanUser">¡Bienvenido {{name_user}}!</span>        
        <div class="nav-options"> 
          <div class="nav__option__item">                  
            <b-dropdown right class="notifications_drop" size="lg">            
              <Notification />
            </b-dropdown>
          </div>
          <div class="nav__option__item">
            <img @click="infoProfile" src="@/assets/images/User.svg" />
          </div>
          <div class="nav__option__item">
            <img @click="logout" src="@/assets/images/Exit.svg" />
          </div>
        </div>
      </div>
    </header>
  </template>
  <script>
  import { AUTH_LOGOUT } from "../store/actions/auth";
  import Notification from "@/components/Notification.vue";
  import apiCall from "../utils/api";
  
  export default {
    components: {
      Notification
    },
    data: function() {
      return {
        name_company: "",
        name_user: ""
      };
    },
    created() {
      var token = localStorage.getItem("user-token");
  
      // apiCall({url:"notification/getnotificationsbyuser", method:"GET"}).then(res => {
      //     this.name_company = res.data.title;
      //     this.name_user = res.data.id;
      // });
      apiCall({url:"user/info?token="+ token, method:"GET"}).then(res => {
  
          this.name_company = res.data.nameCompany;
          this.name_user = res.data.name;

          localStorage.setItem("user-name", res.data.username);
          
      }).catch(() => {
          this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push('/login'));
      });
    },
    mounted() {
        if (!document.getElementById("hs-script-loader")) {
          var script = document.createElement("script");
          script.type = "text/javascript";
          script.id = "hs-script-loader";
          script.async = true;
          script.defer = true;
          script.src = "//js.hs-scripts.com/44152112.js";
          document.body.appendChild(script);
        }
    },
    methods: {
      logout: function() {
        this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push("/login"));
      },
      infoProfile: function() {
        this.$router.push("/Profile");
      },
       home: function() {
        this.$router.push("./");
      }
    }
  };
  </script>
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
  @font-face {
    font-family: Poppins-Regular;
    src: url("../assets/fonts/poppins/Poppins-Regular.ttf");
  }
  @import "../assets/css/SuiteHeader.css";
  </style>
  