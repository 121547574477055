<template>
  <footer><label for="">{{footer_msg}}</label></footer>
</template>
<script>
export default {
  data: function() {
    return {
      footer_msg: "copyright controlt.com.co 2020"
    };
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@font-face {
  font-family: Poppins-Regular;
  src: url("../assets/fonts/poppins/Poppins-Regular.ttf");
}

@import "../assets/css/Footer.css";
</style>
