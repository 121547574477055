<template>
    <div>
        <div v-if="services.length ==0">
            <Loading />
        </div>
        <div v-if="services.length > 0">
            <div class="main__carousel">
                <section class="carousel">
                    <div class="carousel__container">
                        <div v-for="service in services" :key="service.id" class="carousel__item">
                            <div>
                                <a v-if="service.state && service.code == 'BV'" class="btn_service" @click="maps()">
                                    <div @click="popup_info(service)">
                                        <div class="icon-state">
                                            <img v-if="service.state" src="@/assets/images/Servicio_activo.svg" />
                                            <img v-if="!service.state" src="@/assets/images/Servicio_inactivo.svg" />
                                        </div>
                                        <div class="icon-service">
                                             <img v-if="service.code == 'CTB'"
                                                 v-bind:src="require(`@/assets/images/Beta_Torre_de_Control.svg`)" />
                                            <img v-if="service.code == 'CT'"
                                                 v-bind:src="require(`@/assets/images/Monitoreo.svg`)" />
                                            <img v-if="service.code == 'CP'"
                                                 v-bind:src="require(`@/assets/images/ControlPoint.svg`)" />
                                            <img v-if="service.code == 'BUI'"
                                                 v-bind:src="require(`@/assets/images/BI.svg`)" />
                                            <img v-if="service.code == 'EL'"
                                                 v-bind:src="require(`@/assets/images/ELearning_grey.svg`)" />
                                            <img v-if="service.code == 'BV'"
                                                 v-bind:src="require(`@/assets/images/buscar_vehiculo.svg`)" />
                                            <img v-if="service.code == 'SP'"
                                                 v-bind:src="require(`@/assets/images/supply.svg`)" />
                                            <img v-if="service.code == 'OF'"
                                                 v-bind:src="require(`@/assets/images/mas.svg`)" />
                                            <img v-if="service.code == 'YMS'"
                                                 v-bind:src="require(`@/assets/images/yms.svg`)" />
                                        </div>
                                        <div class="go_service">
                                            <a class="btn">{{service.name}}</a>
                                        </div>
                                    </div>
                                </a>

                                <a v-if="service.state && service.code != 'BV'" class="btn_service" :href="service.url" target="_blank">
                                    <div @click="popup_info(service)">
                                        <div class="icon-state">
                                            <img v-if="service.state" src="@/assets/images/Servicio_activo.svg" />
                                            <img v-if="!service.state" src="@/assets/images/Servicio_inactivo.svg" />
                                        </div>
                                        <div class="icon-service">
                                            <img v-if="service.code == 'CTB'" v-bind:src="require(`@/assets/images/Torre_de_Control.svg`)" />
                                            <img v-if="service.code == 'CT'" v-bind:src="require(`@/assets/images/Monitoreo.svg`)" />
                                            <img v-if="service.code == 'CP'" v-bind:src="require(`@/assets/images/ControlPoint.svg`)" />
                                            <img v-if="service.code == 'BUI'" v-bind:src="require(`@/assets/images/BI.svg`)" />
                                            <img v-if="service.code == 'EL'" v-bind:src="require(`@/assets/images/ELearning_grey.svg`)" />
                                            <img v-if="service.code == 'BV'" v-bind:src="require(`@/assets/images/buscar_vehiculo.svg`)" />
                                            <img v-if="service.code == 'SP'" v-bind:src="require(`@/assets/images/supply.svg`)" />
                                            <img v-if="service.code == 'OF'" v-bind:src="require(`@/assets/images/mas.svg`)" />
                                            <img v-if="service.code == 'YMS'" v-bind:src="require(`@/assets/images/yms.svg`)" />
                                        </div>
                                        <div class="go_service">
                                            <a class="btn">{{service.name}}</a>
                                        </div>
                                    </div>
                                </a>

                                <a v-if="!service.state" class="btn_service" v-b-modal.modal-service>
                                    <div @click="popup_info(service)">
                                        <div class="icon-state">
                                            <img v-if="service.state" src="@/assets/images/Servicio_activo.svg" />
                                            <img v-if="!service.state" src="@/assets/images/Servicio_inactivo.svg" />
                                        </div>
                                        <div class="icon-service">
                                             <img v-if="service.code == 'CTB'"
                                                 v-bind:src="require(`@/assets/images/Torre_de_Control.svg`)" />
                                            <img v-if="service.code == 'CT'"
                                                 v-bind:src="require(`@/assets/images/Monitoreo.svg`)" />
                                            <img v-if="service.code == 'CP'"
                                                 v-bind:src="require(`@/assets/images/ControlPoint.svg`)" />
                                            <img v-if="service.code == 'BUI'"
                                                 v-bind:src="require(`@/assets/images/BI.svg`)" />
                                            <img v-if="service.code == 'EL'"
                                                 v-bind:src="require(`@/assets/images/ELearning_grey.svg`)" />
                                            <img v-if="service.code == 'BV'"
                                                 v-bind:src="require(`@/assets/images/buscar_vehiculo.svg`)" />
                                            <img v-if="service.code == 'SP'"
                                                 v-bind:src="require(`@/assets/images/supply.svg`)" />
                                            <img v-if="service.code == 'OF'"
                                                 v-bind:src="require(`@/assets/images/mas.svg`)" />
                                            <img v-if="service.code == 'YMS'"
                                                 v-bind:src="require(`@/assets/images/yms.svg`)" />
                                        </div>
                                        <div class="go_service">
                                            <a class="btn">{{service.name}}</a>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <b-modal size="lg" hide-footer hide-header centered id="modal-service">
                <div class>
                    <img src="@/assets/images/close.png" class="close_model" @click="hideModal()" />
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div style="background:#363666; margin:0;padding:20px 20px 1px 20px">
                            <div class="icon-modal">
                                <img v-if="popup.code == 'CTB'"
                                     v-bind:src="require(`@/assets/images/Beta_Torre_de_Control.svg`)"
                                     height="45" />
                                <img v-if="popup.code == 'CT'"
                                     v-bind:src="require(`@/assets/images/Monitoreo.svg`)"
                                     height="45" />
                                <img v-if="popup.code == 'CP'"
                                     v-bind:src="require(`@/assets/images/ControlPoint.svg`)"
                                     height="45" />
                                <img v-if="popup.code == 'BUI'"
                                     v-bind:src="require(`@/assets/images/BI.svg`)"
                                     height="45" />
                                <img v-if="popup.code == 'EL'"
                                     v-bind:src="require(`@/assets/images/ELearning_grey.svg`)"
                                     height="45" />
                                <img v-if="popup.code == 'BV'"
                                     v-bind:src="require(`@/assets/images/buscar_vehiculo.svg`)"
                                     height="45" />
                                <img v-if="popup.code == 'SP'"
                                     v-bind:src="require(`@/assets/images/supply.svg`)"
                                     height="45" />
                                <img v-if="popup.code == 'OF'"
                                     v-bind:src="require(`@/assets/images/mas.svg`)"
                                     height="45" />
                                <img v-if="popup.code == 'YMS'"
                                     v-bind:src="require(`@/assets/images/yms.svg`)"
                                     height="45" />
                            </div>
                            <p style="color:#FFF; font-size:25px;">{{popup.title}}</p>
                        </div>
                        <div :style="{ backgroundImage: 'url(' + require('@/assets/images/fondo_modal.png') + ')' }"
                             style="padding: 20px;min-height: 310px;">
                            <p style="color:#ff5717; font-size:22px;">Descripción</p>
                            <p v-show="popup.code == 'CT'"
                               style="margin-left: 25px;font-size: 15px;text-align: justify;">ControlT nace para brindar a las empresas de transporte, generadores de carga y operadores logísticos una herramienta para integrar información, administrar la trazabilidad, mejorar el control de la operación, identificar oportunamente riesgos y situaciones operativas que requieran gestión. ControlT se basa en tres pilares: integración de múltiples fuentes de información, toma de decisiones oportunas basadas en datos, comunicación efectiva entre los actores involucrados.</p>
                            <p v-show="popup.code == 'CP'"
                               style="margin-left: 25px;font-size: 15px;text-align: justify;">Aplicación móvil para el trabajo de campo, que permite registrar el paso de un vehículo por puestos de control, control de acceso de puertos, cedis, bodegas; realizar inspecciones en línea; y registrar novedades en ruta. Los registros se visualizan en linea en un entorno web, el cual incluye tanto los datos registrados, como las evidencias fotográficas.</p>
                            <p v-show="popup.code == 'BUI'"
                               style="margin-left: 25px;font-size: 15px;text-align: justify;">Sacale todo el provecho a los datos para tomar decisiones oportunas. Toma decisiones basadas en hechos y no en intuición o percepción. Con nuestra herramienta puedes analizar tanto la información que se genera en nuestras soluciones, y también incluir otras fuentes de datos para tener un panorama más amplio de tu operacion logistica.</p>
                            <p v-show="popup.code == 'EL'"
                               style="margin-left: 25px;font-size: 15px;text-align: justify;">Nuestro equipo esta trabajando en el desarrollo de contenidos para aportar a nuestros clientes herramientas para que el personal funcional fortalezca sus capacidades y conocimientos. Espera proximamente mas informacion.</p>
                            <p v-show="popup.code == 'BV'"
                               style="margin-left: 25px;font-size: 15px;text-align: justify;">Identifica rapidamente si un vehiculo se encuentra o no integrado en nuestro sistema, asi mismo la informacion de la ubicacion GPS del vehiculo y los demas datos recibidos desde el proveedor de GPS. De esta manera podras agilizar el proceso de verificacion del GPS en el proceso de apertura de hojas de vida, y durante el transito del vehiculo podras consultar la ubicacion en una sola plataforma integradora.</p>
                            <p v-show="popup.code == 'SP'"
                               style="margin-left: 25px;font-size: 15px;text-align: justify;">El servicio de proveeduría busca disminuir los tiempos de colocación de vehículos, además, ayudar a fidelizar la flota teniendo en cuenta los vehículos que ya tienen hoja de vida con la compañía. De acuerdo con las necesidades, aplicando filtros como la ubicación y el tipo de vehículo, encontraras rápidamente aquellos que cumplan con el requerimiento.</p>
                            <p v-show="popup.code == 'OF'"
                               style="margin-left: 25px;font-size: 15px;text-align: justify;">El módulo de asignación de servicios es una herramienta que busca facilitar el proceso de comunicación en la asignación de viajes entre Generadores de carga y empresas de transporte; y Empresas de transporte y Transportistas independientes. Usando MAS, evitarás reprocesos, tiempos muertos, información duplicada, o faltante, y podrás tomar decisiones oportunas para garantizar que todos tus viajes sean realizados según tu necesidad..</p>
                            <p v-show="popup.code == 'CTB'"
                               style="margin-left: 25px;font-size: 15px;text-align: justify;">Con nuestra nueva herramienta Torre de Control analiza y organiza de mejor manera la información de valor de tus viajes, logrando mejores métricas, revisión en detalle de rutas, y mejor manejo de la visibilidad de tus datos. Contacta con tu especialista de productos para mas información.</p>
                            <p v-show="popup.code == 'YMS'"
                               style="margin-left: 25px;font-size: 15px;text-align: justify;">El Sistema de Gestión de Patios te permite optimizar la gestión de los patios de carga y descarga de los centros de distribución, además asignar muelles de manera eficiente, monitorear el flujo de vehículos y garantizar una operación logística fluida y sin interrupciones.</p>
                            <div>
                                <a v-if="popup.code == 'CT'"
                                   href="https://controlt.com.co/torre-de-control/"
                                   target="_blank">
                                    <b-button class="btn"
                                              v-show="!popup.state"
                                              style="background:#3688ff">Más Información</b-button>
                                </a>
                                <a v-if="popup.code == 'CP'"
                                   href="https://controlt.com.co/control-point/"
                                   target="_blank">
                                    <b-button class="btn"
                                              v-show="!popup.state"
                                              style="background:#3688ff">Más Información</b-button>
                                </a>
                                <a v-if="popup.code == 'BUI'"
                                   href="https://controlt.com.co/analitica/"
                                   target="_blank">
                                    <b-button class="btn"
                                              v-show="!popup.state"
                                              style="background:#3688ff">Más Información</b-button>
                                </a>
                                <a v-if="popup.code == 'EL'"
                                   href="https://controlt.com.co/campus-virtual/"
                                   target="_blank">
                                    <b-button class="btn"
                                              v-show="!popup.state"
                                              style="background:#3688ff">Más Información</b-button>
                                </a>
                                <a v-if="popup.code == 'BV'" href="https://controlt.com.co/visor/" target="_blank">
                                    <b-button class="btn"
                                              v-show="!popup.state"
                                              style="background:#3688ff">Más Información</b-button>
                                </a>
                                <a v-if="popup.code == 'SP'" href="https://controlt.com.co/proveeduria/" target="_blank">
                                    <b-button class="btn"
                                              v-show="!popup.state"
                                              style="background:#3688ff">Más Información</b-button>
                                </a>
                                <a v-if="popup.code == 'OF'" href="https://offers.controlt.com.co/" target="_blank">
                                    <b-button class="btn"
                                              v-show="!popup.state"
                                              style="background:#3688ff">Más Información</b-button>
                                </a>
                                <a v-if="popup.code == 'CTB'" href="https://controlt.com.co/" target="_blank">
                                    <b-button class="btn"
                                              v-show="!popup.state"
                                              style="background:#3688ff">Más Información</b-button>
                                </a>
                                <a v-if="popup.code == 'YMS'" href="https://agendamiento.controlt.com.co/" target="_blank">
                                    <b-button class="btn"
                                              v-show="!popup.state"
                                              style="background:#3688ff">Más Información</b-button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </b-modal>
        </div>
    </div>
</template>
<script>
    import apiCall from "../utils/api";
    import Loading from "@/components/Loading.vue";

    export default {
        components: {
            Loading
        },
        data: function () {
            return {
                services: [],
                popup: {
                    title: "",
                    description: "",
                    state: false
                }
            };
        },
        created: function () {
            apiCall({ url: "User/GetServicesByUser", method: "GET" }).then(res => {
                var services = [];
                res.data.data.forEach(function (entry) {
                    if (entry.code == "CT" || entry.code == "CP" || entry.code == "SP" || entry.code == "OF" || entry.code == "CTB" || entry.code == "BUI") {
                        entry.url = entry.url + localStorage.getItem("user-token");
                    }
                    if(entry.code == "YMS") {
                        entry.url = entry.url + 'TMS/Default.aspx?id=' + localStorage.getItem("user-name") + '&action=0';
                    }
                    services.push(entry);
                    
                });
                this.services = services;
            });
        },
        methods: {
            popup_info: function (service) {
                this.popup.code = service.code;
                this.popup.title = service.name;
                this.popup.state = service.state;
            },
            hideModal() {
                this.$root.$emit("bv::hide::modal", "modal-service");
            },
            maps() {
                this.$router.push("/maps");
            }
        }
    };
</script>

<style scoped lang="scss">
    @font-face {
        font-family: Poppins-Regular;
        src: url("../assets/fonts/poppins/Poppins-Regular.ttf");
    }

    @import "../assets/css/Services.css";
</style>
