<template>
  <div class="home">
    <Header />
    <section class="containerPpal">
      <section class="content">
        <Services />
      </section>
    </section>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Services from "@/components/Services.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "home",
  components: {
    Header,
    Services,
    Footer
  }
};
</script>

<style lang="scss">
@font-face {
  font-family: Poppins-Regular;
  src: url("../assets/fonts/poppins/Poppins-Regular.ttf");
}

#app > div {
  display: flex;
  flex-flow: column;
  height: 100vh;
  // background: linear-gradient(to top, #1f1853 5%, #ffffff 38%);
}

.containerPpal {
  display: flex;
  height: 80%;
  background-image: url("../assets/images/containerPpal.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top;
  background-size: 100%;
  justify-content: center;
}

.content {
  display: grid;
  align-items: center;
  // outline: 1px solid cyan;
  flex: 1;
}

.notifications_drop button,
.notifications_drop button:active,
.notifications_drop button:hover {
  // background: #fff url("../assets/images/Notificaciones.svg") !important;
  background: none;
  border: none;
  padding: 8px;
  background-repeat: no-repeat !important;
}

.notifications_drop button {
  box-shadow: none !important;
  background: none !important;
}

.notifications_drop .dropdown-menu::before {
  display: none;
}

.notifications_drop .dropdown-menu:focus {
  outline: 0px;
}

.notifications_drop .dropdown-menu {
  padding: 0px;
}

.notifications_drop:focus {
  box-shadow: none;
}

.dropdown-toggle::after {
  content: none !important;
}

#modal-service .modal-content {
  font-family: Poppins-Regular;
  background: #1f1853;
  color: #ffffff;
}

#modal-service .btn {
  background: #ff5717;
  width: 170px;
  float: right;
}

#modal-service a {
  color: #ffffff;
}

#modal-service .row > div {
  padding: 35px;
}

@media only screen and (max-width: 600px) {
  .home {
    height: fit-content !important;
  }
}
</style>