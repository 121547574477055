import axios from "axios";
import qs from "qs";

const url_api = process.env.VUE_APP_URL_API;

const apiCall = ({ url, method, ...args }) => new Promise((resolve, reject) => {
    var token = localStorage.getItem("user-token");
    var config = {};
    if (token != null && token.length > 0) {
        config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
                'Authorization': 'Bearer ' + token
            }
        };
    } else {
        axios.defaults.headers.common["Authorization"] = '';
        config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
            }
        };
    }
    setTimeout(() => {
        try {
            if (method == "GET") {
                axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
                axios.get(url_api + url, qs.stringify(args.data), config).then((res) => {
                    resolve(res);
                }).catch(err => {
                    reject(new Error(err));
                });
            }
            if (method == "POST") {
                axios.post(url_api + url, qs.stringify(args.data), config).then((res) => {
                    resolve(res);
                }).catch(err => {
                    reject(new Error(err));
                });
            }
        } catch (err) {
            reject(new Error(err));
        }
    }, 1000)
})

export default apiCall;