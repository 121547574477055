import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";

Vue.use(VueRouter);

const ifNotAuthenticated = (to, from, next) => {
    //console.log("ifNotAuthenticated", { to: to.path, from: from.path, isAuthenticated: store.getters.isAuthenticated });
    if (to.name === "loginCallBack") {
        next();
        return;
    }
    if (!store.getters.isAuthenticated) {
        next();
        return;
    }
    next("/");
}

const ifAuthenticated = (to, from, next) => {
    //console.log("ifAuthenticated", { to: to.path, from: from.path, isAuthenticated: store.getters.isAuthenticated });
    if (store.getters.isAuthenticated) {
        next();
        return;
    }
    next("/login");
}

const routes = [{
        path: "/",
        name: "home",
        component: Home,
        beforeEnter: ifAuthenticated
    },
    {
        path: "/about",
        name: "about",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/About.vue")
    },
    {
        path: "/login",
        name: "login",
        beforeEnter: ifNotAuthenticated,
        component: () =>
            import ("../views/Login.vue")
    },
    {
        path: "/profile",
        name: "profile",
        beforeEnter: ifAuthenticated,
        component: () =>
            import ("../views/Profile.vue")
    },
    {
        path: "/maps",
        name: "maps",
        beforeEnter: ifAuthenticated,
        component: () =>
            import ("../views/ApiWeb.vue")
    },
    {
        path: "/login/callback",
        name: "loginCallBack",
        beforeEnter: ifNotAuthenticated,
        component: () => 
            import ("../views/CallBack.vue")
    }    
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});
export default router;