import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import BootstrapVue from 'bootstrap-vue';
import axios from 'axios';

import "./registerServiceWorker";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

Vue.use(BootstrapVue)
Vue.config.productionTip = false;
Vue.prototype.$http = axios;
Vue.prototype.$urlApp = process.env.VUE_APP_URL_APP;
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");