<template>
<div class="divContainer">  
  <b-list-group v-for="notification in notifications" :key="notification.id">
    <div>
      <b-list-group-item variant="light">
        <span><i class="fa fa-exclamation-triangle" aria-hidden="true" style="color: #ff5717"></i> {{notification.Description}}</span>
        <b-badge variant="primaary" >  
          {{notification.CreatedOn}}</b-badge>
      </b-list-group-item>
      </div>
  </b-list-group>
  <div v-if="notifications.length == 0"> NO HAY NOTIFICACIONES</div>
</div>
</template>
<script>

//import apiCall from "../utils/api";
export default {
  components: {     
  }, 
  data: function() {
      return {
        notifications: []
      }
  },
  created(){

    //apiCall({url:"notification/getnotificationsbyuser", method:"GET"}).then(res => {
        //this.notifications = res.data.data;
    //});

  },
  // methods: {
  //   mouseOver: function(id){
  //           console.log('notification',id.Id)  
  //           this.token= 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjI2MTkiLCJuYmYiOjE1Njk0Mzg4NzcsImV4cCI6MTU2OTc4NDQ3NywiaWF0IjoxNTY5NDM4ODc3LCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwMTEiLCJhdWQiOiJodHRwOi8vbG9jYWxob3N0OjgwMTEifQ.0VzaKgHbxW2dYr6RnyaTfL_eqhJm1uEpgStJHTbP8Ew'//localStorage.getItem("user-token");
  //           this.id_notification = id.id;
  //           apiCall({ url: 'notification/UpdateNotification?token='+this.token +'&id_notification='+id.Id, data: this.token, method: "GET" }).then(res=> {
  //           //console.log('res', res.data)
  //           this.notifications = res.data.data;
    
  //     })
  //   }  
  // }
}
</script>
<style>
.divContainer{
	margin-right: 5px;
  width: 100%;
}
.dropdown-menu{
  top: 60px;
  right: 0px;
  left: unset;
  width: 460px;
  box-shadow: 0px 5px 7px -1px #c1c1c1;
  padding-bottom: 0px;
  padding: 0px;
}
.dropdown-menu:before{
  content: "";
  position: absolute;
  top: -20px;
  right: 12px;
  border:10px solid #343A40;
  border-color: transparent transparent #343A40 transparent;
}
.head{
  padding:5px 15px;
  border-radius: 3px 3px 0px 0px;
}
.footer{
  padding:5px 15px;
  border-radius: 0px 0px 3px 3px; 
}
.notification-box{
  padding: 10px 0px; 
}
.bg-gray{
  background-color: red;
}
@media (max-width: 640px) {
    .dropdown-menu{
      top: 50px;
      left: -16px;  
      width: 290px;
    } 
    .nav{
      display: block;
    }
    .nav .nav-item,.nav .nav-item a{
      padding-left: 0px;
    }
    .message{
      font-size: 13px;
    }
    
}
</style>
